import { createAction, props } from '@ngrx/store';

import { HousekeeperUsers } from '../../models';

export const loadRequest = createAction(
  '[Housekeeper Users] Load Request',
  props<{ propertyIds: number[] }>(),
);
export const loadSuccess = createAction(
  '[Housekeeper Users] Load Success',
  props<{ users: HousekeeperUsers }>(),
);
export const loadFailure = createAction(
  '[Housekeeper Users] Load Failure',
  props<{ error: any }>(),
);

export const resetState = createAction('[Housekeeper Users] Reset State');
