import { createFeatureSelector, createSelector } from '@ngrx/store';

import { State } from './state';

export const selectState = createFeatureSelector<State>('housekeeperUsers');

export const selectUsers = createSelector(selectState, (state) => state.users);

export const selectIsLoading = createSelector(
  selectState,
  (state) => state.isLoading,
);

export const selectError = createSelector(selectState, (state) => state.error);
