import { HousekeeperUsers } from '../../models';

export interface State {
  users: HousekeeperUsers;
  isLoading: boolean;
  error: any;
}

export const initialState: State = {
  users: null,
  isLoading: false,
  error: null,
};
