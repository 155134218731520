import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { HousekeeperUsersStoreEffects } from './effects';
import { housekeeperUsersReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('housekeeperUsers', housekeeperUsersReducer),
    EffectsModule.forFeature([HousekeeperUsersStoreEffects]),
  ],
})
export class HousekeeperUsersStoreModule {}
